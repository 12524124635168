import React, { useContext, useState } from 'react'
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage'
import Header from '../../components/Header/Header'
import ImageContainer from '../../components/ImageContainer/ImageContainer'
import { signInWithGoogle } from '../../services/Firebase'
import { useNavigate } from 'react-router-dom'
import { backgrounds } from '../../data/backgrounds'
import { iconsData } from '../../data/icons'


const CheckoutDummy = ({ backgroundImageUrl, native = true, subject }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        if (native) {
            signInWithGoogle()
        }
    }

    const handleTOSClick = () => {
        //tbd
        navigate('/tos');
    }

    const getPronoun = () => {
        console.log('subject', subject)
        if (subject?.gender) {
            switch (subject.gender.toLowerCase()) {
                case 'male':
                    return 'he';
                case 'female':
                    return 'she';
                case 'non-binary':
                    return 'this';
                default:
                    return 'this'; // A neutral pronoun for unknown or unspecified gender
            }
        } else {
            return 'this'; // A neutral pronoun for cases where gender is not present
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '100vh' }}>
            <BackgroundImage imageUrl={backgrounds['checkout']} />


            {!native ? (
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-evenly', marginBottom: '20px' }}>
                        <div style={{marginBottom: 10}}>
                            <p style={{ fontWeight: 'bolder', fontSize: '3em', color: '#FFE500', marginBottom: '10px', fontWeight: '900', textAlign: 'center' }}>Only for 18+</p>
                            <p style={{ fontWeight: 'bolder', fontSize: '1.3em', color: '#FFF', marginBottom: '10px', textAlign: 'center' }}>See before it's <span style={{color: '#474747'}}>deleted</span> 🔥</p>
                        </div>

                        <div style={{ display: 'flex', alignSelf: 'center',justifyContent: 'center', position: 'relative', margin: 20, height: '65vh', width: '85vw', background: 'linear-gradient(to bottom, rgb(255, 37, 103) 0%, rgb(252, 112, 0) 81%)' ,alignItems: 'center', borderRadius: 20, 
                         }}>
                        <ImageContainer imageUrl={backgroundImageUrl}>
                            <div style={{ position: 'absolute', bottom: 0, left: 0, padding: '10px', color: '#FFF', backgroundColor: 'rgba(0, 0, 0, 0.1)', borderRadius: '0 20px', paddingLeft: '20px', width: '100vw' }}>
                                
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <img src={iconsData['blurredName']} height={20} width={100} style={{marginBottom: '10px'}}/>
                                    <img src={iconsData['blurredTime']}  height={20} width={150} style={{marginBottom: '10px'}}/>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <div style={{ backgroundColor: '#FFE500', width: '10px', height: '10px', marginRight: '10px', borderRadius: '50%' }}></div>
                                    <h3 style={{ fontWeight: 'bold', fontSize: '1.2em', color: '#FFF', margin: '0' }}>{`See steps below`}</h3>
                                </div>
                            </div>
                            <img src={iconsData['noEyes']} style={{position: 'absolute', top: '50%', left: '50%', width: '100px', height: '100px',  transform: 'translate(-50%, -50%)' }} />
                        </ImageContainer>

                        </div>

                        <div style={{marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                 <p style={{ fontWeight: 'bolder', fontSize: '1.5em', color: '#FFF', marginBottom: '10px' , marginRight: '15px' }}>👉  Tap </p>
                                  <img src={iconsData['menuIcon']} width={50} height={50}  style={{ marginRight: '15px'}}/>
                                 <p style={{ fontWeight: 'bolder', fontSize: '1.5em', color: '#FFF', marginBottom: '10px' }}>on top right</p>
                            </div>
                           
                            <p style={{ fontWeight: 'bolder', fontSize: '1.5em', color: '#FFF', marginTop: '20px' }}>👉  Open in <span style={{color: '#FFE500'}}>Chrome</span></p>
                        </div>

                         {/* Animated Pointy Finger */}
                <div style={{
                position: 'absolute',
                top: '30px',
                right: '10px',
                width: '80px',
                height: '80px',
                background: `url(${iconsData['pointyFinger']}) no-repeat center center`,
                backgroundSize: 'contain',
                animation: 'bounce 1s infinite'
            }} />

            <style>
                {`
                    @keyframes bounce {
                        0%, 100% {
                            transform: translateY(0);
                        }
                        50% {
                            transform: translateY(-10px);
                        }
                    }
                `}
            </style>

                    </div>
            ) : (
                <>
                     <div style={{ display: 'flex', alignSelf: 'center',justifyContent: 'center', position: 'relative', margin: 20, height: '65vh', width: '85vw', background: 'linear-gradient(to bottom, rgb(255, 37, 103) 0%, rgb(252, 112, 0) 81%)' ,alignItems: 'center', borderRadius: 20, 
             }}>
                        <ImageContainer imageUrl={backgroundImageUrl}>
                            {/* captions */}
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    padding: '10px',
                                    color: '#FFF',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    borderRadius: '0 20px',
                                    padding: '30px',
                                    width: '100vw'
                                }}
                            >
                                <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '-20px' , flexDirection: 'column', marginLeft: -30}}>
                                    {/* Circular image avatar with source as subject?.image */}
                                    <img src={iconsData['meme']} alt="Meme" style={{  height: '200px', width: '200px' }} />
                                    {/* <img src={subject?.image} alt="User Avatar" style={{ borderRadius: '50%', border: '1px solid white', marginRight: '10px', height: '50px', width: '50px' }} /> */}
                                </div>
                                <h3 style={{ fontWeight: 'bold', fontSize: '2em', color: '#FFF' }}><span style={{color: '#FFE500'}}>1 Step</span> away </h3>
                                <h3 style={{ fontWeight: 'bold', fontSize: '2em', color: '#FFF' }}>from the <span style={{color: '#FFE500'}}>Secret</span> 🤫</h3>
                                <p style={{ color: '#FFF', fontSize: '0.8em', marginTop: '10px' }}>By Signing up, you agree to our <span style={{ color: '#FFE500', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleTOSClick}>TOS</span></p>
                            </div>

                            <img src={iconsData['questionMark']} width={70} height={100}  style={{position: 'absolute', top: '30%', left: '50%', transform: 'translate(-30%, -50%)'}}/>
                        </ImageContainer>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px', margin: '5px 0px' }}>
                        <button className="exit-button" onClick={handleClick} style={{backgroundImage: `url(${iconsData['yellowBtn']})`, backgroundSize: 'cover', backgroundPosition: 'center',backgroundRepeat: 'no-repeat', backgroundColor: '#FFE500', fontSize: '1.3em', fontWeight: '700'}}>
                            {native ? `Sign up to Unlock  👀` : null}
                        </button>
                    </div>
                </>
            )}

               
        </div>
    )
}

export default CheckoutDummy